.component {
  & > :not(:last-child) {
    margin-bottom: var(--size-lg);
  }
}

.header {
  & > :not(:last-child) {
    margin-bottom: var(--size-md);
  }
}
