.component {
  & > .storySidebarLayout {
    height: 100%;
  }
}

.componentSidebarNavigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: var(--duration-sm);
  transition-property: color, background-color;
}
