.component {
  line-height: var(--line-height-normal);
  font-family: var(--font-family-base);
  font-size: var(--font-size-20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: var(--size-24) 0;
  border-top: 2px solid;

  & > .body {
    margin-right: var(--size-xs);
  }
}

.text {
  display: flex;
  flex-direction: column;
}

.iconRight {
  transition: transform var(--duration-sm) ease-in-out;

  & > * {
    width: 100%;
    height: auto;
  }
}
