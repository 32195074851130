.page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10vh 0;
}

.layout {
  display: grid;
  grid-template-areas:
    'title    title'
    'subtitle text';
  grid-template-columns: 2fr 3fr;
  grid-column-gap: var(--size-lg);
  grid-row-gap: var(--size-xl);

  & > * {
    min-width: 0;
  }

  & > .title {
    grid-area: title;
  }

  & > .subtitle {
    grid-area: subtitle;
  }

  & > .text {
    grid-area: text;
  }
}
